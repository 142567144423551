span.toc-label-prefix {
  font-weight: 100;
  color: #666;
}

span.toc-label-text {
  font-weight: 500;
  color: #444;
}

span.toc-label-prefix-0 {
  font-weight: 600;
}

span.toc-label-text-0 {
  font-weight: 600;
}

#page-controls-wrapper .page-control-widget.enhanced-toc {
  padding-top: 12px;
  padding-bottom: 12px;
}

.page-control-widget.enhanced-toc .page-control-widget-content ul {
  margin: 0;
  padding-bottom: 0;
  display: block;
}
