.pubDocTypeDialogContent {
  overflow: visible;
}

.pubDocTypeDialogContent.loading {
  min-height: 40vh;
}

.pubDocTypeDialogName {
  width: 100%;
}

.pubDocTypeDialogDescription {
  width: 100%;
}