/*
 * Copyright 2018 General Code
 */
.questions {
  margin: 16px 0 16px 0;
  display: grid;
  grid-row-gap: 8px;
}

.questions:empty {
  display: none;
}

.questions .question {
  width: 100%;
  padding: 8px 24px;
  border-left-width: 7px;
  border-left-style: solid;
  position: relative;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
  background-color: var(--paper-background-color);
}

.questionContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
}

.question:first-child {
  margin-top: 0;
}

.questionTitle {
  color: #1e1e1e;
  white-space: nowrap;
  min-height: 30px;
  font-size: 24px;
  font-family: freight-sans-pro, sans-serif;
  font-weight: 500;
  padding: 0 0 8px;
  line-height: 1.1875em;
  margin-bottom: 8px;
  flex: 0 0 auto;
  cursor: pointer;
  text-decoration: none;
}

.questionReferences {
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
  background-color: #FCFCFC;
}

.collapsed .questionReferences {
  margin-bottom: 0;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background: none;
}

.questionExpansionControls {
  margin-bottom: 20px;
}

.questionReferences .questionReference + .questionReference {
  margin-top: 32px;
}

.questionText {
  padding: 12px 28px 0 10px;
  margin-bottom: 10px;
  flex: 1 1 100%;
  font-variant-numeric: proportional-nums lining-nums;
}

.questionContainer .questionDetails {
  overflow: hidden;
  display: block;
}

.collapsed.questionContainer .question > div {
  display: flex;
}

.collapsed.questionContainer .questionTitle {
  padding: 0;
  margin-bottom: 0;
}

.analysisDue {
  font-size: large;
  width: fit-content;
  background-color: yellow;
}

.collapsed.questionContainer .questionText {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
  white-space: nowrap;
  padding: 5px 28px 0 10px;
  margin-bottom: 0;
  width: 10px;
}

.collapsed.questionContainer .questionActionsContainer, .collapsed.questionContainer .questionError {
  display: none;
}

.collapsed.questionContainer .questionReferences {
  margin: -10px 0 6px 0;
  display: none;
}

.questionReferences .clickable.muiChip .titleIndexNum {
  font-weight: 500;
}

.collapsed.questionContainer .titleNumber, .collapsed.questionContainer .titleTitle, .collapsed.questionContainer .questionReferenceContent {
  display: none;
}

.collapsed.questionContainer .questionReferenceLabel {
  display: inline-block;
}

.collapsed.questionContainer .questionReference {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}

.questionReset {
  display: block;
  float: right;
  margin-left: 5px;
}

.questionNote {
  display: block;
  float: right;
}

.collapsed .questionOption, .collapsed .questionOption.selected, .collapsed .questionOption .selected, .collapsed .questionReset, .collapsed .questionNote, .collapsed .questionLock {
    display: none;
}

.questionContainer .questionState {
  display: block;
  padding: 9px 0 0 10px;
  flex: 0 0 28px;
  position: absolute;
  right: 10px;
  top: 0;
}

.questionContainer .questionState svg {
  height: 28px;
  width: 28px;
  fill: black;
}
.questionContainer .questionState.complete svg {
  fill: #008000;
}
.questionContainer .questionState.complete.finalized svg {
  fill: #B2B2B2;
}
.questionContainer .questionState.error svg {
  fill: #D32F2F;
}

.questionLock {
  display: block;
  float: right;
  margin-left: 5px;
}

.reviewerSelectContainer {
  margin: 0 24px 0 0;
  flex-shrink: 1;
  flex-grow: 1;
}

.reviewerSelectContainer .formSelectControl {
  min-width: 120px;
}

.questionActionsContainer {
  display: flex;
  align-items: flex-end;
}

.lastEditContainer {
  margin-bottom: 12px;
}

.lastEditContainer a {
  cursor: pointer;
  font-size: 12px;
  font-variant-numeric: proportional-nums lining-nums;
  opacity: 0.8;
}
.lastEditContainer a:hover {
  text-decoration: underline;
}

.collapsed.questionContainer .lastEditContainer {
  display: none;
}

.historyEvent {
  display: flex;
}

.historyAccessTime {
  width: 160px;
  font-variant-numeric: proportional-nums lining-nums;
  font-weight: 600;
}

.historyMessage {
  max-width: 534px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.needsReviewContainer {
  flex: 0 0 auto;
}

#questionFiltersContainer {
  margin-bottom: 24px;
}

#questionFiltersContent {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  flex-direction: column;
}

.questionFiltersContentShadow {
  border-color: #49A942 !important;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
  border-radius: 4px;
  background-color: var(--paper-background-color);
  padding: 8px;
}

#questionFiltersActions {
  flex-shrink: 0;
  flex-grow: 0;
}

#questionFilters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.formSelectControl {
  margin-right: 8px;
}

.page-control-widget .formSelectControl {
  max-width: 100%;
}

#needsReviewFilterSelect {
  min-width: 124px;
}

#assignedFilterSelect {
  min-width: 88px;
}

#assignedToFilterSelect {
  min-width: 108px;
  flex-shrink: 1;
  flex-grow: 5;
}

#deferrableFilterSelect {
  min-width: 144px;
}

#statusFilterSelect {
  min-width: 122px;
}

#questionFilters .filterInputContainer {
  position: relative;
  margin-top: 16px;
  min-width: 190px;
  flex-shrink: 1;
  flex-grow: 5;
}

#questionFilters .filterInputContainer #filterInputIcon {
  position: absolute;
  top: 0;
  left: 0;
}

#filterInput {
  flex-shrink: 1;
  flex-grow: 1;
  width: 95%;
}

#filterInputIcon + #filterInput {
  padding-left: 28px;
}

#questionFilters .questionFilter {
  width: 100%;
}

#questionFilters .questionFilter > select {
  margin-top: 8px;
}

#questionFiltersActions > * {
  margin-left: 8px;
}

#questionFilters .filterLabel {
  flex: 1 1 100%;
}

.analysis {
  min-height: 110vh;
}

.analysisHeader {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
}

.analysisHeader .analysisLabelContainer {
  flex: 1 1 100%;
}

.finalizeButtonContainer {
  flex-grow: 0;
  flex-shrink: 0;
  float: right;
}

.analysisLabelContainer .analysisLabel, .analysisHeader .finalizedDateContainer {
  font-size: 24px;
  font-weight: 500;
}

.analysisHeader .finalizedDateContainer {
  flex-grow: 0;
  flex-shrink: 0;
}

.analysisLabelContainer .analysisMessage {
  font-weight: 600;
}

.questionError {
  font-weight: 500;
}

.questionError, .analysisMessage .error {
  color: #D32F2F;
}

.analysisMessage .success {
  color: #008000;
}

.singleSelect.questionOption .questionError, .multiSelect.questionOption .questionError {
  margin-left: 10px;
}

.reactButtonWrapper + .reactButtonWrapper {
  margin-left: 6px;
}

.questionHtml ol, .questionHtml ul {
  padding-left: 40px;
}
.questionHtml ol {
  list-style-type: decimal;
}
.questionHtml ul {
  list-style-type: disc;
}
.questionHtml blockquote {
  border-left: 5px solid #ccc;
  margin: 0;
  padding: 1em 0 1em 40px;
}
.questionHtml table table, .questionHtml table td, .questionHtml table th
{
  border: 1px solid #aaa;
  border-collapse: collapse;
  padding: 6pt;
}
.questionHtml .marker-yellow {
  background-color: #fdfd77;
}
.questionHtml .marker-green {
  background-color: #62f962;
}
.questionHtml .marker-pink {
  background-color: #fc7899;
}
.questionHtml .marker-blue {
  background-color: #72ccfd;
}
.questionHtml .text-tiny {
  font-size: x-small;
}
.questionHtml .text-small {
  font-size: small;
}
.questionHtml .text-big {
  font-size: large;
}
.questionHtml .text-huge {
  font-size: x-large;
}
.questionHtml .pen-red {
  background: none;
  color: #e71313;
}
.questionHtml .pen-green {
  background: none;
  color: #128a00;
}

.questionReferenceLabel .titleNumber, .questionReferenceLabel .titleTitle {
  float: none;
  display: inline;
  font-size: 20px;
  padding-left: 0;
}

.questionReferenceBreadcrumbs {
  margin-bottom: 8px;
  font-size: .91rem;
  line-height: 24px;
  color: #333;
  font-variant-numeric: proportional-nums lining-nums;
}

.questionReferenceBreadcrumbs .resultType {
  font-weight: 600;
}

.questionReferenceBreadcrumbs .resultParentLink {
  color: #333;
  text-decoration: none;
}

.questionReferenceBreadcrumbs .titleLink:hover, .questionReferenceBreadcrumbs .resultParentLink:hover, .questionReferenceBreadcrumbs .titleLink:hover .highlight, .questionReferenceBreadcrumbs .resultParentLink:hover .highlight, .questionReferenceBreadcrumbs a:hover .highlight {
  text-decoration: underline;
}

.questionReferenceBreadcrumbs .resultParentSeparator {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.54);
}

.questionReferenceBreadcrumbs .titleNumber, .questionReferenceBreadcrumbs .titleTitle {
  float: none;
  display: inline;
  font-size: 20px;
  padding-left: 0;
}

.questionReferenceBreadcrumbs .resultTypeIcon {
  float: left;
  margin: 0 2px 0 0;
  height: 24px;
}

.optionListLabel, .questionSectionLabel {
  font-weight: 600;
}

.optionListLabel {
  margin-left: 10px;
}

.collapsed .optionListLabel {
  display: none;
}

.printSection {
  display: none;
}

.questionNoteDisplay {
  margin-top: 16px;
}

.discussionPanel {
  background-color: var(--paper-background-color);
  margin-top: 16px;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
}

.discussionPanelLabel {
  cursor: pointer;
}

.discussionPanelLabel:hover .expansionPanelLabel {
  text-decoration: underline;
}

.discussionPanelContent {
  width: 100%;
}

.commentInputArea {
  display: flex;
  align-items: center;
}

.commentInput {
  width: 100%;
  flex: 1 1 auto;
}

.discussionPanelContent .commentButton {
  margin: 4px 0 0 4px;
  height: 42px;
}

.questionComments {
  margin: 12px 8px;
}

.questionComment {
  margin: 12px 0;
}

.commentAuthor {
  font-weight: 600;
}

.commentDate {
  color: #787878;
  margin-left: 12px;
}

.commentActions {
  margin-bottom: 2px;
}

.commentAction {
  color: #787878;
  font-weight: 500;
  margin-right: 8px;
  cursor: pointer;

  /* undo being a button */
  background-color: transparent;
  border: none;
  padding: 0;
}

.toggleHistoryButton {
  /* undo being a button */
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.collapsed .questionDiscussionContainer {
  display: none;
}

.image-style-standard-float-left {
  float: left;
}

.image-style-standard-float-left img {
  margin-right: 1.5em !important;
  width: 400px;
  border: 1px solid #ccc;
}

.image-style-standard-center {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 400px;
}

.image-style-standard-center img {
  width: 400px;
  border: 1px solid #ccc;
}

.image-style-standard-float-right {
  float: right;
}

.image-style-standard-float-right img {
  margin-left: 1.5em !important;
  width: 400px;
  border: 1px solid #ccc;
}

.image-style-small-float-left {
  float: left;
}

.image-style-small-float-left img {
  margin-right: 1.5em !important;
  width: 150px;
  border: 1px solid #ccc;
}

.image-style-small-center {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 150px;
}

.image-style-small-center img {
  width: 150px;
  border: 1px solid #ccc;
}

.image-style-small-float-right {
  float: right;
}

.image-style-small-float-right img {
  margin-left: 1.5em !important;
  width: 150px;
  border: 1px solid #ccc;
}

.image-style-full-width {
  display: flex;
  justify-content: center;
}

#analysisStatusLink {
  color: black;
  position: relative;
}

#analysisStatusLink span {
  color: #aaa;
  background-color: transparent;
  display: inline-block;
  position: absolute;
  top: 4px;
}

#analysisStatusLink span:hover {
  color: #333;
}

.question figure.image img {
  max-width: 100%;
}

@media print {

  .printSection {
    display: block;
  }

  #additionalPageTitleContent .moreActions button, #additionalPageTitleContent .moreActions a[role='button'], #questionFilters, .questionIcon, .formSelectControl svg, .finalizeButtonContainer, .noteButton, .questionReset, #questionFiltersActions, .questionExpansionControls {
    display: none !important;
  }

  .questionContainer {
    padding: 16px 8px 0 8px;
    margin-bottom: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.42);
  }

  .questions .question {
    padding: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }

  .collapsed.questionContainer .question > div {
    display: block;
  }

  .questions .questionContainer .questionDetails {
    overflow: visible;
    max-height: none !important;
  }

  .collapsed.questionContainer .questionTitle {
    padding: 0 0 8px;
    margin-bottom: 8px;
  }

  .questionReferences {
    margin-top: 0;
    margin-bottom: 0;
    padding: 12px 12px 0 12px;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .collapsed.questionContainer .questionReferences {
    margin: 0;
    display: block;
    padding: 12px 12px 0 12px;
    border-radius: 4px;
    box-shadow: none;
    background: none;
  }

  .questionReferences .questionReference {
    margin: 8px 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }

  .questionText {
    padding-top: 0;
  }

  .collapsed.questionContainer .questionText {
    height: auto;
    white-space: normal;
    padding: 0 28px 0 10px;
    margin-bottom: 10px;
    width: auto;
    overflow: auto;
  }

  .formSelectControl :before {
    border-bottom: none;
  }

  .formSelectControl label {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }

  .formSelectControl.blank div[role='button']:before {
    content: "Unassigned";
  }

  .questionOptions .questionOption .questionOptionInput, .questionOptions .questionOption .questionOptionInput *, .optionFileContainer {
    display: inline;
    width: auto;
  }

  .questions, .questionContainer, .additionalOptionContent .optionText, .additionalOptionContent .optionText *,
  .questionOptions *[role='radiogroup'], .questionContainer .questionText p {
    display: block;
  }

  .collapsed .questionDiscussionContainer, .collapsed.questionContainer .questionActionsContainer,
  .collapsed.questionContainer .questionError,.collapsed .questionOption, .collapsed .questionOption.selected,
  .collapsed .questionOption .selected, .collapsed .questionNote, .collapsed.questionContainer .lastEditContainer {
    display: block;
  }

  .questionDiscussionContainer .discussionPanel {
    background-color: transparent;
    box-shadow: none;
  }

  .questionDiscussionContainer .discussionPanelContent::before {
    content: "Internal Discussion:";
    font-weight: bold;
  }

  .questionDiscussionContainer .discussionPanelLabel {
    display: none;
  }

  .questionDiscussionContainer .discussionPanelLabel + div {
    height: auto !important;
  }

  .questionDiscussionContainer .discussionPanelLabel + div > div > div > div {
    padding: 0;
  }

  .questionDiscussionContainer .commentInputArea {
    display: none;
  }

  .questionDiscussionContainer .questionComments {
    margin: 0 0 0 8px;
  }

  .questionDiscussionContainer .commentActions {
    display: none;
  }

  /* Important needed here because the styles are applied as a style= on the element itself so normal css can't touch it */
  .questionFiltersSticky {
    min-height: initial !important;
  }

  .questionFiltersSticky .sticky {
    position: absolute !important;
    top: 0 !important;
    width: auto !important;
  }

  .questionFiltersSticky .customerAccentColor_Border {
    box-shadow: none;
  }

  #analysisStatusLink, .questionContainer .questionState, div.optionFileContainer, .questionLock {
    display: none;
  }

  /* Dont allow circle and label to break between */
  .questionOptionTextDiv {
    break-before: avoid;
  }

  .questionRadioButtonDiv {
    break-after: avoid;
  }

  .questionFlexDiv {
    break-inside: avoid;
  }
}
