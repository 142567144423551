.calculatorForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.calculatorFormControl {
  margin-bottom: 18px;
  flex: 1 0 100%;
}

.calculatorForm .calcButton {
  margin-top: 8px;
}

.bufferyardControlContainer {
  width: 400px;
}

.parkingFormControlContainer {
  width: 500px;
}

.calculatorNotes ol {
  margin: 10px;
  padding-left: 16px;
  list-style: upper-roman;
}

.parkingCalculatorNotes ol {
  margin: 10px;
  padding-left: 16px;
  list-style: upper-alpha;
}

.bufferyardClassTable ol {
  margin: 10px;
  padding-left: 8px;
  list-style: decimal;
}

.bufferyardClassTable ul {
  margin: 10px;
  padding-left: 8px;
  list-style: disc;
}

.specificNotes ol {
  margin: 10px;
  padding-left: 16px;
  list-style: decimal;
}

.specificNotes ol.specific-notes-sublist {
  margin-top: 4px;
  padding-left: 16px;
  list-style: lower-alpha;
}

ul.compliance-sublist {
  margin-top: -12px;
  padding-left: 52px;
  font-size: 12pt;
  list-style: disc;
}

.bufferyardClassTable img, .signageResultTable img {
  width: 100%;
  height: auto;
}

.parkingResultTable td, .parkingResultTable th {
  border-bottom-width: 0px;
}

.parkingResultTable td.lastRow, .parkingResultTable th.lastRow {
  border-bottom-width: 2px;
}


