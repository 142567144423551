.homeHalfPage {
  display: flex;
  gap: 32px;
}

.homeHalfPage > * {
  flex: 1 1 50%;
}

.homeContainer {
  margin: 32px 0 0 0;
  padding: 8px;
}

.homeTitle.largeTitle {
  margin-top: 0;
}

.graphicalFigure {
  display: inline-block;
  margin-right: 16px;
  margin-left: 16px;
  text-align: center;
}

.graphicalLink {
  text-decoration: none;
  color: inherit;
  border-radius: 4px;
  min-width: 112px;
  max-width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a.graphicalLink:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.graphicalLinkRow {
  place-content: center;
  display: flex;
  flex-wrap: wrap;
}

.graphicalCaption {
  text-align: center;
  color: #005D85;
  font-size: 24px;
  font-weight: 600;
}

.graphicalImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.home-page-icon {
  height: 48px;
  width: 48px;
  }