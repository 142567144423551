.reactButtonWrapper {
  display: inline-block;
}

.reactButtonWrapper span {
  text-shadow: none;
}

#page-controls-wrapper .page-control-widget.actionButtonsPageControl {
  padding: 8px;
}

.actionButtonsPageControl .actionButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2px;
  row-gap: 8px;
}

.actionButtonsPageControl .actionButtonsWrapper .reactButtonWrapper + .reactButtonWrapper {
  margin-left: 0;
}