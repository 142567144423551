
.noteEditControls {
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  margin-bottom: 16px;
}

.noteEditControls .noteEditControls-colorPicker, .noteEditControls .noteEditControls-displayModePicker {
  flex: 0 1 160px;
}

.noteEditControls .noteEditControls-visibilityPicker {
  flex: 1 1 auto;
}

.noteEditControls  .reactButtonWrapper {
  flex: 0 0 64px;
  margin-top: 32px;
}

.noteEditControls .reactButtonWrapper + .reactButtonWrapper {
  margin-left: 0;
}

@media (max-width: 759px) {
  .responsive .noteEditControls {
    display: none;
  }
}
