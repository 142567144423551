
.checkboxListFacetOption {
  margin: 8px 0;
}

.checkboxListFacetOptionCheckbox, .checkboxListFacetOptionGroupCheckbox {
  padding: 0 8px 0 12px;
}

.filterAccordion {
  margin-right: 16px;
}

.filterAccordionSummary {
  padding: 0 12px;
}

.filterAccordionSummary .filterAccordionSummaryExpanded:not([role="button"]) {
  margin: 12px 0;
}

.filterAccordionSummary.filterAccordionSummaryExpanded {
  min-height: 48px;
}

.filterAccordionDetails {
  flex-direction: column;
  padding: 0 12px 12px;
}

.filterAccordionButton {
  right: 0;
}

.checkboxListFacetOptionGroup.grouped .checkboxListFacetOption {
  margin-left: 12px;
}